<template>
  <div>
    <ul class="list-group profile-menu transform">
      <accordion-forms v-if="clientData.filter_form" :filters="clientData.filter_form" :url="clientData.project_url" v-on:filteredItems="filteredItems" :excel="clientData.kol_excel_download" :resetData="resetData"></accordion-forms>
      <li class="list-group-item" style="padding: 0px;">
        <div style="position: fixed; bottom: 0; height: 75px; width: 280px; background-color: #f5f5f5; color: #333; border-top: 1px solid #ccc; text-align: center; padding: 10px 0px;">
          <img src="https://imgs.simpsonhealthcare.com/simpson/powered-by-3connex.png" height="30"/><br/>
          <span style="font-size: 12px;">&copy;{{currentDate}} Simpson Healthcare</span>
        </div>
      </li>
    </ul>
    <!-- Page Content -->
    <div id="page-content-wrapper">
      <div class="container-fluid">
        <div style="background: #fff; height: 70px; border-bottom: 1px solid #ccc; left: 0; top: 80px; position: fixed; right: 0px; left: 280px; z-index: 2;">
          <div class="d-flex justify-content-start" style="padding-top: 15px; padding-left:25px; padding-right: 25px;">
            <font-awesome-icon icon="search" style="font-size: 35px;" />
            <input type="text" id="search" placeholder="Search KOL" style="padding-left: 10px; padding-right: 15px; font-size: 25px; width: 100%; border:0; outline:0;" v-model="searchKOL" v-on:keyup="lookup(searchKOL)">
          </div>
        </div>
        <!-- EE Filters -->
        <div style="background: #f5f5f5; position:fixed; left: 280px; right: 0; top: 150px; height:70px; z-index: 2; box-shadow:4px 4px 5px rgba(0, 0, 0, 0.2);">
          <div class="d-flex justify-content-between">
              <div style="padding: 20px 15px;">
                <div style="padding-left: 20px; padding-top: 5px; padding-bottom: 5px; font-size: 14px;">
                    <div id="filter-number">SHOWING {{kolData.count}} KOL(S)</div>
                </div>
            </div>
            <div class="dropdown" style="padding: 20px 25px;">
              <button class="btn btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-transform:uppercase; font-size:14px;">
                Sort By
              </button>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="#" id="name-asc"  @click="getSortFilters('name-asc')"><font-awesome-icon icon="sort-alpha-down"/> Last Name</a>
                <a class="dropdown-item" href="#" id="name-desc" @click="getSortFilters('name-desc')"><font-awesome-icon icon="sort-alpha-down-alt"/> Last Name</a>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid View -->
        <div id="ei-content container-fluid" style="overflow-y: auto;background: #f5f5f5; position: fixed;left: 280px; right: 0; top: 220px; z-index: 1; bottom: 0;padding: 10px 10px 20px 15px;">
          <div class="row" style="padding-left: 0px; margin: 0px; background: #f5f5f5;">
            <div v-for="item in kolData.kols" :key="item.kol_id" class="col-12 col-sm-6 col-md-4 py-3">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex justify-content-end" v-if="hasAccess() ">
                    <font-awesome-icon icon="edit" @click="eidtProfile(item)"/>
                  </div>
                  <div class="profile-image" :style="{backgroundImage:`url(${clientData.kol_image_url}${getImage(item)})`}"></div>
                  <h2 style="font-size: 18px; font-weight: bold;">{{item.first_name}} {{item.last_name}}</h2>
                  <h4 class="text-muted" style="font-size: 16px;">{{item.specialty}}</h4>

                  <div class="text-center pt-4">
                    <h5 style="font-size:16px;">Address</h5>
                    <address v-if="item.hasOwnProperty('address_1')">
                      {{item.address_1}}<br>
                      {{item.city}}, {{item.state}} {{item.zip_postcode}} {{item.country}} 
                    </address>
                    <address v-else>
                      <span v-if="item.address.address_1">{{item.address.address_1}}<br></span>
                      {{item.address.city}}, {{item.address.state}} {{item.address.zip_postcode}} {{item.address.country}}
                    </address>

                    <h5 style="font-size:16px;" class="pt-2">Email</h5>
                    <p>{{item.email}}</p>
                    
                    <div v-if="item.phone">
                      <h5 style="font-size:16px;" class="pt-2">Phone</h5>
                      <p>{{item.phone}}</p>    
                    </div>
                    
                  </div>
        
                  <div class="info-card-footer-multiple" v-if="has_multiple_download_btns">
                    <a :href="`${clientData.kol_pdf_url}${item.pdf_file}`" target="_blank" class="btn btn-secondary btn-block" style="font-weight: 500;"><font-awesome-icon icon="cloud-download-alt"/> Download Profile PDF</a>
                  </div>
                  <div class="info-card-footer" v-else-if="(item.tier >= 2 || item.tier == 'No Tier') && clientData.project_id == '5655'">
                    <a :href="`${clientData.kol_mini_pdf_url}${item.kol_id}`" target="_blank" class="btn btn-secondary btn-block" style="font-weight: 500;"><font-awesome-icon icon="cloud-download-alt"/> Download Profile PDF</a>
                  </div>
                  <div class="info-card-footer" v-else>
                    <a :href="getURL(item)" target="_blank" class="btn btn-secondary btn-block" style="font-weight: 500;"><font-awesome-icon icon="cloud-download-alt"/> Download Profile PDF</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="edit-modal" id="modal-scrollable" scrollable size="lg" :title="editData.full_name">
          <b-tabs>
            <b-tab title="Profile" active class="text-left">
              <profile-form v-if="clientData.profile_form" :filters="clientData.profile_form" :formData="editData" />
            </b-tab>
            <b-tab title="Profile PDF" @click="resetPDFUpload">
              <form enctype="multipart/form-data" novalidate>
                <div class="dropbox">
                  <input type="file" :disabled="isSaving" @change="uploadProfilePDF"
                    accept="application/pdf" class="input-file">
                    <p v-if="isInitial">
                      Drag your profile PDF here to begin<br> or click to browse
                    </p>
                    <p v-if="isSaving">
                      Uploading file...
                    </p>
                    <p v-if="isSuccess">
                      File has been uploaded successfully
                    </p>
                    <p v-if="isFailed">
                      File failed to upload
                    </p>
                </div>
              </form>
            </b-tab>
            <b-tab title="Engagement PDF" @click="resetPDFUpload">
              <form enctype="multipart/form-data" novalidate>
                <div class="dropbox">
                  <input type="file" :disabled="isSaving" @change="uploadEngagementPDF"
                    accept="application/pdf" class="input-file">
                    <p v-if="isInitial">
                      Drag your engagement PDF here to begin<br> or click to browse
                    </p>
                    <p v-if="isSaving">
                      Uploading file...
                    </p>
                    <p v-if="isSuccess">
                      File has been uploaded successfully
                    </p>
                    <p v-if="isFailed">
                      File failed to upload
                    </p>
                </div>
              </form>
            </b-tab>
            <!-- <b-tab title="Profile Picture">
              <div class="d-flex justify-content-start align-items-center p-3">
                <div class="text-center">
                  <div class="profile-image" :style="{backgroundImage:`url(${clientData.kol_image_url}${getImage(editData)})`}"></div>
                  <div>Current Picture</div>
                </div>
                <div class="pl-4">
                    <img :src="previewImage" class="uploading-image" />
                    <div style="padding-top: 10px; ">
                      <input ref="profilePic" type="file" accept="image/png, image/gif, image/jpeg" @change=uploadImage>
                    </div>
                </div>
              </div>
              
            </b-tab> -->
          </b-tabs>
          <template #modal-footer="{ cancel, save }">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button variant="danger" @click="cancel()">
              Cancel
            </b-button>
            <b-button variant="success" @click="saveProfileEdit()">
              Save
            </b-button>
          </template>
        
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import AccordionForms from '@/components/AccordionForms.vue'
import ProfileForm from '../components/ProfileForm.vue'
import { forEach } from 'lodash'

export default {
  name: 'Profiles',
  components: {
		AccordionForms,
    ProfileForm
  },
  data() {
    return {
      currentDate: new Date().getFullYear(),
      specialty: ['Allergist/Immunologist', 'Immunologist'],
      inputForm: {},
      archetypes: ['Research/Clinical','Academic','Patient Care (Hospital)','Patient Care (Private Practice)'],
      colorType: ['Red', 'Yellow', 'Green'],
      activity: ['1:1 Engagement','Advisory Board','Congress Presentation','Content Committee','Consultant','Speaker Bureau'],
      year: ['2018','2019','2020'],
      congress: ['General','AAAI', 'ACAAI', 'CIS', 'WSAAI'],
      generalActive: false,
      generalClickedIcon: 'chevron-right',
      archetypeActive: false,
      archetypeClickedIcon: 'chevron-right',
      engagementActive: false,
      engagementClickedIcon: 'chevron-right',
      clientData: {},
      kolData: {},
      searchKOL: null,
      inputForm: {},
      resetData: false,
      has_multiple_download_btns: false,
      editData : {},
      previewImage: null,
      status_initial: 0,
      status_saving: 1,
      status_success: 2,
      status_failed: 3,
      currentStatus: null,
      userRole: JSON.parse(localStorage.getItem('user')).roles
    }
  },
  computed: {
    isInitial() {
      return this.currentStatus === this.status_initial
    },
    isSaving() {
      return this.currentStatus === this.status_saving
    },
    isSuccess() {
      return this.currentStatus === this.status_success
    },
    isFailed() {
      return this.currentStatus === this.status_failed
    }
  },
  methods: {
    toggleGeneral: function () {
      this.generalActive = !this.generalActive
      if (this.generalActive === true) {
        this.generalClickedIcon = 'chevron-down'
      } else {
        this.generalClickedIcon = 'chevron-right'
      }
    },
    getImage: function (item) {
      console.log(item)
      console.log(item.hasOwnProperty('image'))
      if(item.hasOwnProperty('photo')) {
        if(item.photo && item.photo.trim() !== ""){
          return encodeURIComponent(item.photo.trim())
        }
      }
      if(item.hasOwnProperty('image')) {
        if(item.image && item.image.trim() !== ""){
          return encodeURIComponent(item.image.trim())
        }
      }
    },
    hasAccess: function() {
      // console.log(this.userRole.includes('editor'))
      console.log(this.urserRole)
      if(this.userRole !== null) {
        return this.userRole.includes('editor')
      } else {
        return false
      }
      
    }, 
    toggleArchetype: function () {
      this.archetypeActive = !this.archetypeActive
      if (this.archetypeActive === true) {
        this.archetypeClickedIcon = 'chevron-down'
      } else {
        this.archetypeClickedIcon = 'chevron-right'
      }
    },
    eidtProfile: function(data) {
      this.editData = data
      this.$refs['edit-modal'].show()
    },
    toggleEngagement: function () {
      this.engagementActive = !this.engagementActive
      if (this.engagementActive === true) {
        this.engagementClickedIcon = 'chevron-down'
      } else {
        this.engagementClickedIcon = 'chevron-right'
      }
    },
    getData: function () {
       axios.get(`https://simpsonapi.com/v2/conventions/3x-data/${localStorage.getItem('project')}`).then(res => {
         this.clientData = res.data.data
         this.has_multiple_download_btns = this.clientData.multiple_download_btns
        this.getKOLs()
       })
    },
    getKOLs: function () {
      console.log(this.clientData.project_url)
      const indication = localStorage.getItem('access_type')
      let url = ''
      if(this.clientData.has_multiple_indications) {
        url = `${this.clientData.project_url}${this.clientData.db_name}/${indication}/kols`
      } else {
        url = `${this.clientData.project_url}${this.clientData.db_name}/kols`
      }

      axios.get(url).then(res => {
        //  console.log(res)
         this.kolData = res.data
       })
    },

    resetPDFUpload: function () {
      this.currentStatus = this.status_initial
    },

    uploadImage: function (e) {
        const image = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = e =>{
            this.previewImage = e.target.result;
            console.log(this.previewImage);
        };

        const URL = `${this.clientData.kol_upload_url}upload-image.php`; 

        let data = new FormData();
        data.append('name', this.editData.image);
        data.append('id', this.editData.kol_id);
        data.append('file', e.target.files[0]); 

        console.log(data)
        axios.post(URL, data, {
          header: {
            'Content-Type': 'multipart/form-data' 
          }
        }).then(response => {
            console.log('image upload response > ', response)
            if(response.data.message == 'Image Uploaded') {
              this.editData.photo = response.data.image;
              this.$refs['profilePic'].value = '';
            }
        })
    },

    uploadProfilePDF(e) {
        // handle file changes
        let formData = new FormData();

        formData.append('name', this.editData.pdf_file);
        formData.append('id', this.editData.kol_id);
        formData.append('file', e.target.files[0]); 
        // save it
        // this.save(formData);
        console.log(formData)

        const URL = `${this.clientData.kol_upload_url}upload-profile-pdf.php`; 

        this.currentStatus = this.status_saving
        axios.post(URL, formData, {
          header: {
            'Content-Type': 'multipart/form-data' 
          }
        }).then(response => {
          if(response.status === 200) {
            this.currentStatus = this.status_success
          }
        }).catch(err => {
            this.uploadError = err.response;
            this.currentStatus = this.satus.failed
          });
      },

      uploadEngagementPDF(e) {
        // handle file changes
        let formData = new FormData();

        if(this.editData.engagement_pdf === null) {
          this.editData.engagement_pdf = this.editData.pdf_file
        }
       

        formData.append('name', this.editData.engagement_pdf);
        formData.append('id', this.editData.kol_id);
        formData.append('file', e.target.files[0]); 
        // save it
        // this.save(formData);
        console.log(formData)

        const URL = `${this.clientData.kol_upload_url}upload-engagement-pdf.php`; 

        this.currentStatus = this.status_saving
        axios.post(URL, formData, {
          header: {
            'Content-Type': 'multipart/form-data' 
          }
        }).then(response => {
          if(response.status === 200) {
            this.currentStatus = this.status_success
          }
        }).catch(err => {
            this.uploadError = err.response;
            this.currentStatus = this.satus.failed
          });
      },

    saveProfileEdit: function () {
      this.editData.image = this.editData.photo
      delete this.editData.photo
      console.log(this.editData)
      const url =  `${this.clientData.project_url}${this.clientData.db_name}/kols/${this.editData.kol_id}`; 
      axios.put(url, this.editData).then(res => {
        console.log(res)
        this.$refs['edit-modal'].hide();
        this.previewImage = null;
        this.currentStatus = this.status_initial
        this.getData();
        // this.filteredItems();
        // this.getFilteredData();
      })
    },
    lookup: function (name) {
      if(name === '') {
        this.getKOLs()
      } else {
        let url = ''
        const indication = localStorage.getItem('access_type')
        if (this.clientData.has_multiple_indications) {
          url = `${this.clientData.project_url}${this.clientData.db_name}/${indication}/searchkol/${name}`
        } else {
          url = `${this.clientData.project_url}${this.clientData.db_name}/searchkol/${name}`
        }
        axios.get(url).then(res => {
        //  console.log(res)
         this.kolData = res.data
       })
      }
    },
    getURL: function (item) {
      let url = ''
      if (this.clientData.has_multiple_indications) {
        url = `${this.clientData.kol_pdf_url}${item.molecule_indication}-pdf.php?id=${item.kol_id}`
      } else if(this.clientData.kol_pdf_url == "") {
        url = `https://www.simpsonhealthcare.com`
      }else {
        url = `${this.clientData.kol_pdf_url}${item.kol_id}`
      }
      return url
    },
    filteredItems: function (data) {
      console.log('Formatted filters:', data)
      this.inputForm = data
      this.getFilteredData()
    },
    getSortFilters: function (item) {
      this.inputForm.sort = {
        field: item.split('-')[0],
        direction: item.split('-')[1].toUpperCase()
      }
      this.getFilteredData()
    },
    getFilteredData: function () {
      let url = ''
      const indication = localStorage.getItem('access_type')
      if (this.clientData.has_multiple_indications) {
        url = `${this.clientData.project_url}${this.clientData.db_name}/${indication}/kols/filter`
      } else {
        url = `${this.clientData.project_url}${this.clientData.db_name}/kols/filter`
      }

      // Send the formatted filter object
      axios.post(url, this.inputForm).then(res => {
        this.kolData = res.data
      })
    },
    resetFilters: function () {
      this.inputForm = {}
      this.resetData = true
    }
  },
  mounted () {
    this.getData()
    this.currentStatus = this.status_initial

  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.profile-menu {
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 280px;
  list-style-type: none;
  margin: 0;
  padding: 77px 0 0 0;
  background: #fff;
  z-index: 1000;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
}

.profile-menu li {
  display: inline-block;
  color: #999;
  position: relative;
  border-bottom: 1px solid rgba(0,0,0,0.05);
}

.profile-menu .menu-header{
  width: 100%;
  height: 3em;
  line-height: 3em;
}

#page-content-wrapper {
    position: fixed;
    right: 0;
    top: 80px;
    padding: 0px;
    left: 280px;
    height: 100%;
    width: 100%;
    overflow-y: hidden;
}

.profile-image {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  background-size: cover;
  display: inline-block;
}

.uploading-image {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  background-size: cover;
  display: inline-block;
  border: 1px solid #ccc;
}

.info-card-footer {
  margin-top: 25px;
  background: #fff;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.3);
}


.info-card-footer-multiple {
  margin-top: 25px;
  background: #fff;
}

.dropbox {
    margin-top: 10px;
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background: #fcfcfc;
    color: #000;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }
  
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }
  
  .dropbox:hover {
    background: #ccc; /* when mouse over to the drop zone, change color */
  }
  
  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
</style>