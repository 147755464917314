<template>
  <div>
    <li class="list-group-item" style="color: #fff; background-color: #006bb6; border-radius: 0px;">
      <div class="d-flex justify-content-between">
        <div style="text-transform: uppercase; font-weight: bold; padding-left: 0px;"><font-awesome-icon icon="filter"/> Filters</div>
        <div style="padding-right: 0px; color: #fff; text-transform:uppercase; cursor:pointer;" @click="resetFilters">Reset</div>  
      </div>
    </li>
    <li class="list-group-item" style="padding: 10px; border: none;">
      <button type="button" class="btn btn-secondary" style="width:220px;" @click="downloadExcel"><span class="fa fa-download"></span> DOWNLOAD EXCEL</button>
      <div class="text-left pt-4" style="position: fixed; overflow-y:auto; width: 270px; top: 190px; bottom: 75px; padding-right: 15px;">
        <div v-for="(accordion, index) in accordions" :key="index">
          <div class="d-flex justify-content-between" @click="toggleAccordion(index, accordion.type)" style="padding: 0px; cursor: pointer;" data-toggle="collapse" :href="'#'+accordion.type_id">
            <h3 class="text-muted" style="font-size: 16px;">{{accordion.type}}</h3>
            <font-awesome-icon :icon="accordion.icon"/>
          </div>
          <div class="collapse" :id="accordion.type_id" style="padding-bottom: 15px;">
            <p v-if="accordion.note" style="font-weight: bold; font-size: 13px;" class="text-primary">{{accordion.note}}</p>
            <div v-for="item in accordion.fields" :key="item.id">
              <div v-if="item.type == 'input' && !item.heatmap_only">
                <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;"><span v-if="accordion.show_field_id" class="badge badge-primary">{{item.id}}</span> {{item.label}}</h4>
                <input :type="item.input_type" :placeholder="item.placeholder" class="form-control" v-model="inputForm[item.vmodel]" />
              </div>
              <div v-if="item.type == 'dropdown'">
                <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;"><span v-if="accordion.show_field_id" class="badge badge-primary">{{item.id}}</span> {{item.label}}</h4>
                <small v-if="item.note">{{item.note}}</small>
                <multiselect :options="item.data" :allow-empty="true" :show-labels="false" :multiple="item.multiple" :placeholder="item.placeholder" v-model="inputForm[item.vmodel]" @select="getSelected(item)" @input="getValue"></multiselect>
              </div>
              <div v-if="item.type == 'dropdown-state' && inputForm['country'] == 'United States'">
                <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;"><span v-if="accordion.show_field_id" class="badge badge-primary">{{item.id}}</span> {{item.label}}</h4>
                <multiselect :options="item.data" track-by="name" label="name" :allow-empty="true" :show-labels="false" :multiple="item.multiple" :placeholder="item.placeholder" v-model="inputForm[item.vmodel]" @select="getSelected(item)" @input="getValue"></multiselect>
              </div>
              <div v-if="item.type == 'dropdown-specialty' && accessType == 'all'">
                <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;"><span v-if="accordion.show_field_id" class="badge badge-primary">{{item.id}}</span> {{item.label}}</h4>
                <multiselect :options="item.data" :allow-empty="true" :show-labels="false" :multiple="item.multiple" :placeholder="item.placeholder" v-model="inputForm[item.vmodel]" @select="getSelected(item)" @input="getValue"></multiselect>
              </div>
              <!-- <div v-if="item.type == 'dropdown-indication' && accessType == 'all'">
                <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;"><span v-if="accordion.show_field_id" class="badge badge-primary">{{item.id}}</span> {{item.label}}</h4>
                <multiselect :options="item.data" :allow-empty="true" :show-labels="false" :multiple="item.multiple" :placeholder="item.placeholder" v-model="inputForm[item.vmodel]" @select="getSelected(item)" @input="getValue"></multiselect>
              </div> -->
              <div v-if="item.type == 'dropdown-indication'">
                <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;"><span v-if="accordion.show_field_id" class="badge badge-primary">{{item.id}}</span> {{item.label}}</h4>
                <multiselect :options="item.data" :allow-empty="true" :show-labels="false" :multiple="item.multiple" :placeholder="item.placeholder" v-model="inputForm[item.vmodel]" @select="getSelected(item)" @input="getValue"></multiselect>
              </div>
              <div v-if="item.type == 'radio-btn'">
                <h4 style="font-weight: 400; font-size: 14px; margin-top: 10px; margin-bottom: 5px;">{{item.label}}</h4>
                <!-- <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
                  <label v-for="(rb, rbIndex) in item.data" :key="rbIndex" class="btn btn-primary">
                    <input type="radio" :value="rb" v-model="inputForm[item.vmodel]" @click="checkItem(item, rb)"> {{rb}}
                  </label>
                </div> -->
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                    class="w-100"
                    id="btn-radios-1"
                    v-model="inputForm[item.vmodel]"
                    :options="item.data"
                    :aria-describedby="ariaDescribedby"
                    name="radios-btn-default"
                    button-variant="primary"
                    @change="checkedItem(item)"
                    buttons
                  ></b-form-radio-group>
                </b-form-group>
              </div>
              <div v-if="item.type == 'radio-btn-indication' && item.molecule_type.includes(accessType)">
                <h4 style="font-weight: 400; font-size: 14px; margin-top: 10px; margin-bottom: 5px;">{{item.label}}</h4>
                <!-- <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
                  <label v-for="(rb, rbIndex) in item.data" :key="rbIndex" class="btn btn-primary">
                    <input type="radio" :value="rb" v-model="inputForm[item.vmodel]" @click="checkItem(item, rb)"> {{rb}}
                  </label>
                </div> -->
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                    class="w-100"
                    id="btn-radios-2"
                    v-model="inputForm[item.vmodel]"
                    :options="item.data"
                    :aria-describedby="ariaDescribedby"
                    name="radios-btn-default"
                    button-variant="primary"
                    @change="checkedItem(item)"
                    buttons
                  ></b-form-radio-group>
                </b-form-group>
              </div>
              <div v-if="item.type == 'checkbox'" style="margin-top: 15px;">
                <h4 style="font-weight: 400; font-size: 14px; margin-top: 10px; margin-bottom: 5px;">{{item.label}}</h4>
                <div class="form-check" v-for="(cb, cbIndex) in item.data" :key="cbIndex">
                  <input class="form-check-input" type="checkbox" :value="rb" v-model="inputForm[item.vmodel]" @change="checkedItem(item)">
                  <label class="form-check-label">
                    {{cb}}
                  </label>
                </div>
              </div>
              <div style="margin-left: 0px; margin-top: 15px;" v-if="item.type == 'toggle-btn'">
                <div class="d-flex justify-content-between">
                  <h4 style="text-transform: uppercase; font-size: 14px;">{{item.label}}</h4>
                  <font-awesome-icon v-if="item.has_info_icon" :id="'popover-target-'+item.id" icon="info-circle" style="cursor:pointer; font-size: 20px; color:#006bb6;"/>
                  <b-popover v-if="item.has_info_icon" :target="'popover-target-'+item.id" triggers="click" placement="right" boundary="window">
                    <template #title>{{item.info_details.title}}</template>
                    {{item.info_details.content}}
                  </b-popover>
                </div>
                <label class="switch">
                    <input type="checkbox" v-model="inputForm[item.vmodel]" @change="checkedItem(item)">
                    <div class="slider round"></div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
    
  </div>
</template>

<script>
import axios from 'axios'
import { forEach } from 'lodash'
export default {
  name: 'AccordionForms',
  props: {
    filters: Object,
    url: String,
    formData: Object,
    resetData: Boolean,
    excel: String
  },
  data() {
    return {
      accordions: [],
      dpvmodel: '',
      inputForm: {},
      accessType: JSON.parse(localStorage.getItem('access_type'))
    }
  },
  methods: {
    toggleAccordion: function(index, type) {
      if(this.accordions[index].type === type) {
        this.accordions[index].toggle = !this.accordions[index].toggle
        if(this.accordions[index].toggle === true) {
          this.accordions[index].icon = 'chevron-down'
        } else {
          this.accordions[index].icon = 'chevron-right'
        }
      }
    },
    checkItem: function(item, value) {
      this.inputForm[item.vmodel] = value
      if(this.formData) {
        this.resetData(item)
      } else {
        this.$emit('filteredItems', this.inputForm)
      }
      // this.filterData()
    },

    checkedItem: function (item) {
      this.formatAndEmitFilters()
    },
    getSelected: function(item) {
      this.dpvmodel = item.vmodel
    },
    getValue: function(action) {
      this.inputForm[this.dpvmodel] = action
      this.formatAndEmitFilters()
    },
    resetFilters: function () {
      this.inputForm = {}
      this.formatAndEmitFilters()
    },
    isEmptyObject: function isObjectEmpty(obj) {
        let isEmpty = true
        forEach(obj, item => {
          if (item !== null) {
            isEmpty = false
          }
        })
        return isEmpty
    },
    downloadExcel: function () {
      let data = '';
      // console.log(this.isEmptyObject(this.inputForm))
      // console.log(this.inputForm)
      if(!this.isEmptyObject(this.inputForm)){
          let items = {}
          forEach(this.inputForm, (item, key) => {
            if(item !== null) {
              items[key] = item
            }
          })
          // console.log(items)
          data = JSON.stringify(items);
      }else{
          data = "all"  
      }

      console.log(data)
      console.log(`${this.excel}?data=${data}`)
      if(this.accessType !== null) {
        window.location.href = `${this.excel}?type=${this.accessType}&data=${data}`
      } else {
        window.location.href = `${this.excel}?data=${data}`
      }
      
    },
    formatAndEmitFilters: function() {
      const formattedFilters = {
        table: 'kols',
        filters: {},
        sort: {}
      }

      // Process each filter value
      forEach(this.inputForm, (value, key) => {
        if (value !== null && value !== '') {
          // Handle different types of filters based on field type
          if (Array.isArray(value)) {
            formattedFilters.filters[key] = {
              operator: 'IN',
              value: value
            }
          } else if (typeof value === 'string') {
            formattedFilters.filters[key] = {
              operator: 'LIKE',
              value: value
            }
          } else if (typeof value === 'boolean') {
            formattedFilters.filters[key] = {
              operator: '=',
              value: value ? 1 : 0
            }
          }
        }
      })

      // Handle sort if present
      if (this.inputForm.sort_by) {
        const [field, direction] = this.inputForm.sort_by.split('-')
        formattedFilters.sort = {
          field: field,
          direction: direction.toUpperCase()
        }
      }

      this.$emit('filteredItems', formattedFilters)
    }
    // filterData: function() {
    //   axios.post(`${this.url}kols/filter`, JSON.stringify(this.inputForm)).then(res => {
    //      console.log(res.data)
    //      this.$emit('filteredItems', res.data)
    //   })
    // }
  },
  mounted () {
    // console.log(this.resetData)
    // console.log('filter', this.filters)
    this.accordions = this.filters.filters
    let data = {}
    forEach(this.accordions, fields => {
      forEach(fields.fields, item => {
        data[item.vmodel] = null
      })
    })
    // console.log(data)
    this.inputForm = data
    // console.log(this.accordions)
  }
}
</script>
<style scoped>
/* Rounded sliders */

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 18px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #009370;
}

input:focus+.slider {
    box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
